import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Menu.css';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is on a mobile device
    const checkIsMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
        setIsMobile(true);
      }
    };

    checkIsMobile();

    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true); // Show the install option if it's mobile and installable
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`User choice: ${outcome}`);
      setDeferredPrompt(null); // Reset the deferred prompt
      setIsInstallable(false); // Hide the install option
    }
  };

  return (
    <div className="menu">
     {isInstallable && isMobile && (
        <button onClick={handleInstallClick} className="install-button">
          Instalar App
        </button>
      )}
      <button onClick={toggleMenu} className="menu-button">
        ☰ Menu
      </button>
      {isOpen && (
        <div className="dropdown">
          <ul>
            <li>
              <Link to="/products">Produtos</Link>
            </li>
            <li>
              <Link to="/">Comandas</Link>
            </li>
            <li>
              <Link to="/login" onClick={handleLogout} className="logout-button">Logout</Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Menu;