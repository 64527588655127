import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const fetchWithAuth = async (url, options = {}, navigate) => {
  const token = localStorage.getItem('authToken');
  const headers = {
    ...options.headers,
    'Authorization': `Bearer ${token}`,
  };

  try {
    const response = await fetch(url, { ...options, headers });
    console.log(navigate)
    if (response.status === 401) {
      navigate('/login');
      return;
    }

    if (response.status >= 400 && response.status < 500) {
      const error = await response.json()
      toast.error(error.error);
      throw Error("erro")
    }

    if (response.status === 500) {
      toast.error('Ocorreu um erro interno');
      throw Error("erro")
    }


    return response;
  } finally {

  }
};

export default fetchWithAuth;