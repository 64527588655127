import React from 'react';

const ProductList = () => {
  return (
    <div>
      <h1>Lista de Produtos</h1>
      {/* Conteúdo da página de produtos */}
    </div>
  );
};

export default ProductList;