import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import TicketList from '../components/TicketList.js';
import OrderList from '../components/OrderList.js';
import ProductList from '../components/ProductList.js';
import Menu from '../components/Menu.js';
import Login from '../components/Login.js';
import { checkAuth } from '../services/auth/checkAuth.js';
import { NavigationProvider } from '../context/NavigationContext.js';
import { useNavigate } from 'react-router-dom';

const ConditionalMenu = () => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login';
    const navigate = useNavigate();
    useEffect(() => {
        if (!isLoginPage) {
            checkAuth(navigate);

            const intervalId = setInterval(() => {
                checkAuth(navigate);
            }, 60000);
            return () => clearInterval(intervalId);
        }
    }, [isLoginPage, navigate]);

    return !isLoginPage && <Menu />;
};

const AppRoutes = () => (
    <Router>
        <ConditionalMenu /> { }
        <NavigationProvider>
            <Routes>
                <Route path="/" element={<TicketList />} />
                <Route path="/orders" element={<OrderList />} />
                <Route path="/products" element={<ProductList />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </NavigationProvider>
    </Router>
);

export default AppRoutes;