import fetchWithAuth from "../fetch/fetchWithAuth.js";
import {API_URL} from '../../config/api.js'
const authCheckURL = API_URL+'/auth/check'

export const checkAuth = async (navigate) => {
    try {
     await fetchWithAuth(authCheckURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }, navigate);
    } catch (error) {
      console.error('check auth error', error);
      throw error;
    }
  };