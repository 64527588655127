import {API_URL} from '../../config/api.js'
const authURL = API_URL+'/auth'

export const login = async (email, password) => {
    try {
      const response = await fetch(authURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to authenticate');
      }
  
      const data = await response.json();
      return data.token; // Presumindo que a API retorne um token
    } catch (error) {
      console.error('Erro de autenticação:', error);
      throw error;
    }
  };