import { fetchWithAuth } from '../fetch/fetchWithAuth.js'; 
import {API_URL} from '../../config/api.js'

const ticketsURL = API_URL+'/tickets';
const token = localStorage.getItem('authToken');

export const getTickets = async (navigate) => {
  try {
    const response = await fetchWithAuth(ticketsURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Substitua pelo token real
      },
    }, navigate);

    if (!response.ok) {
      throw new Error('Erro ao buscar tickets');
    }

    const data = await response.json();
    return data || []; 
  } catch (error) {
    console.error(error);
    return []; 
  }
};

export const addTicket = async (ticket, navigate) => {
  try {
    const response = await fetchWithAuth(ticketsURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(ticket),
    }, navigate);
  
    const data = await response.json()
    return data.ticket_id
  } catch (e) {
    console.log(e)
    throw e
  }
};

export const updateTicket = async (id, ticket, navigate) => {
  await fetchWithAuth(`${ticketsURL}/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(ticket),
  }, navigate);
};

export const deleteTicket = async (id, navigate) => {
  await fetchWithAuth(`${ticketsURL}/${id}`, {
    method: 'DELETE',
  }, navigate);
};