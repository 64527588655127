import React, { useState } from 'react';
import { addTicket, updateTicket } from '../services/tickets/api.js';
import { useLoading } from '../context/LoadingContext.js'; 
import { useNavigate } from 'react-router-dom'; 

const TicketForm = ({ currentTicket, onAddTicket }) => {
  const [name, setname] = useState(currentTicket ? currentTicket.name : '');
  const { isLoading, setIsLoading } = useLoading();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currentTicket) {
        await updateTicket(currentTicket.id, { name }, navigate);
      } else {
        setIsLoading(true); 
        var id = await addTicket({ name }, navigate);
        setIsLoading(false); 
      }
  
      var ticket = {name, id}
      onAddTicket(ticket);
      setname('');
    } catch (e) {
      setIsLoading(false); 
      console.log(e)
    }

  };

  return (
    <div>
    {isLoading}
    <form className="ticket-form" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Comanda"
        value={name}
        onChange={(e) => setname(e.target.value)}
      />
      <button type="submit">{currentTicket ? 'Atualizar' : 'Adicionar'}</button>
    </form>
    </div>
  );
};

export default TicketForm;