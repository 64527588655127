import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import "../styles/OrderList.css"

const OrderList = () => {
  const { state } = useLocation();
  const { ticket } = state || {}; // Retrieve the ticket object

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/'); 
  };
  return (
    <div>
      <h1>Order Details</h1>
      {ticket ? (
        <div>
          <button className="back-button" onClick={handleBackClick}>Voltar para a lista de tickets</button>
          <h2>Pedidos da comanda {ticket.name}</h2>
          <p>ID: {ticket.id}</p>
          {/* Display other ticket details */}
        </div>
      ) : (
        <p>Ticket não encontrado.</p>
      )}
    </div>
  );
};

export default OrderList;
