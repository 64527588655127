import React from 'react';
import AppRoutes from './routes/AppRoutes.js'; 
import { LoadingProvider, useLoading } from './context/LoadingContext.js';
import Loading from './components/Loading.js';
import {ToastContainer} from 'react-toastify';

const AppContent = () => {
  const { isLoading } = useLoading(); // Certifique-se de que está recebendo o estado correto
  return (
    <>
      {isLoading && <Loading />} {/* Verifica se o estado isLoading está true */}
      <AppRoutes />
    </>
  );
};



const App = () => {
  return (
    <LoadingProvider>
      <ToastContainer /> 
      <AppContent />
    </LoadingProvider>
  );
};

export default App;