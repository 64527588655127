import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { getTickets } from '../services/tickets/api.js'; 
import '../styles/TicketList.css';
import TicketForm from './TicketForm.js';
import { useLoading } from '../context/LoadingContext.js'; 

const TicketList = () => {
  const { isLoading, setIsLoading } = useLoading();
  const [tickets, setTickets] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleClick = (ticket) => {
    navigate('/orders', { state: { ticket } }); // Pass the ticket object as state
  };

  const handleAddTicket = async (ticket) => {
    setTickets([...tickets, ticket]);
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const data = await getTickets(navigate);
        setTickets(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Erro ao buscar tickets:', error);
        setIsLoading(false);
        setTickets([]);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [setIsLoading, navigate]);

  return (
    <div>
      <TicketForm onAddTicket={handleAddTicket}></TicketForm>
      {isLoading} {}
      <div className="ticket-list">
        {tickets.length === 0 ? (
          <p>Nenhum ticket encontrado</p>
        ) : (
          tickets.map(ticket => (
            <div
              key={ticket.id}
              className="ticket-card"
              onClick={() => handleClick(ticket)}
            >
              {ticket.name}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TicketList;